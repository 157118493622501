import React, { useState, useEffect, useRef } from 'react';
import LazyLoad from 'react-lazyload';
import './App.css';
import ArrowDown from './icon/ArrowDown.jsx';
import ImageWithLoader from './components/ImageWithLoader';

const Product = ({ name, price, description, image, onShowDetails }) => (
  <div className="product" onClick={() => onShowDetails({ name, price, description, image })}>
    <ImageWithLoader src={image} alt={name} className="product-image" />
    <h3>{name}</h3>
    <p className="product-description">{description}</p>
    <p className="price">{price} <span className="rub">Р</span></p>
  </div>
);

const App = () => {
  const [products, setProducts] = useState([]);
  const [activeCategory, setActiveCategory] = useState(''); // Активная категория
  const [showDetails, setShowDetails] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const categoryListRef = useRef(null); // Список категорий (горизонтальный скролл)
  const categoryRefs = useRef([]); // Рефы для категорий в шапке

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('./data/products.json');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setProducts(data.products);
        const uniqueCategories = [...new Set(data.products.map(product => product.category))];
        setCategories(uniqueCategories);
        setActiveCategory(uniqueCategories[0]); // Устанавливаем первую категорию активной
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      }
    };

    fetchProducts();
  }, []);

  const handleShowDetails = (product) => {
    setSelectedProduct(product);
    setShowDetails(true);
  };

  const handleCloseDetails = () => {
    setShowDetails(false);
  };

  const scrollToCategory = (category, index) => {
    const section = document.getElementById(category);
    if (section) {
      // Скролл до соответствующей секции без анимации
      window.scrollTo({
        top: section.offsetTop - 100, // Учитываем высоту шапки
        behavior: 'auto', // Переход без анимации
      });
      setActiveCategory(category); // Устанавливаем активную категорию при клике
    }

    // Плавное перемещение активной категории в центр шапки
    const activeElement = categoryRefs.current[index];
    const containerWidth = categoryListRef.current.offsetWidth;
    const activeElementLeft = activeElement.offsetLeft;
    const activeElementWidth = activeElement.offsetWidth;
    const scrollPosition = activeElementLeft - (containerWidth / 2) + (activeElementWidth / 2);

    // Прокрутка горизонтального списка категорий с анимацией
    categoryListRef.current.scrollTo({
      left: scrollPosition,
      behavior: 'smooth',
    });
  };

  // Центрирование активной категории в шапке (используется при смене категории при скроллинге)
  const centerActiveCategory = (category) => {
    const index = categories.indexOf(category);
    if (index !== -1) {
      const activeElement = categoryRefs.current[index];
      const containerWidth = categoryListRef.current.offsetWidth;
      const activeElementLeft = activeElement.offsetLeft;
      const activeElementWidth = activeElement.offsetWidth;
      const scrollPosition = activeElementLeft - (containerWidth / 2) + (activeElementWidth / 2);

      categoryListRef.current.scrollTo({
        left: scrollPosition,
        behavior: 'smooth',
      });
    }
  };

  // Логика для отслеживания заголовков <h2> и обновления активной категории
  useEffect(() => {
    const headerHeight = document.querySelector('.header').offsetHeight; // Получаем высоту шапки

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const category = entry.target.getAttribute('data-category');
          // Проверяем, пересекает ли элемент нижнюю границу шапки
          if (entry.isIntersecting) {
            setActiveCategory(category); // Активируем категорию, когда заголовок в зоне шапки
            centerActiveCategory(category); // Центрируем категорию в шапке
          }
        });
      },
      {
        root: null,
        rootMargin: `-${headerHeight}px 0px 0px 0px`, // Учитываем высоту шапки
        threshold: 0, // Обрабатываем пересечение, когда заголовок попадает в зону видимости
      }
    );

    const headers = document.querySelectorAll('.category-section h2');
    headers.forEach((header) => observer.observe(header));

    return () => {
      headers.forEach((header) => observer.unobserve(header));
    };
  }, [categories]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCategorySelect = (category, index) => {
    closeModal(); // Закрываем модальное окно
    scrollToCategory(category, index); // Переходим к выбранной категории
  };

  return (
    <div className="app">
      <header className="header">
        <nav>
          <ul ref={categoryListRef} className="category-list">
            {categories.map((category, index) => (
              <li
                key={category}
                ref={(el) => (categoryRefs.current[index] = el)}
                onClick={() => scrollToCategory(category, index)}
                className={activeCategory === category ? 'active' : ''}
              >
                {category}
              </li>
            ))}
          </ul>
        </nav>
        <div className="arrow-down-wrapper" onClick={openModal}>
          <ArrowDown />
        </div>
      </header>

      {categories.map((category) => (
        <section key={category} id={category} className="category-section">
          <h2 data-category={category}>{category}</h2>
          <div className="product-list">
            {products
              .filter((product) => product.category === category)
              .map((product) => (
                <Product
                  key={product.id}
                  name={product.name}
                  price={product.price}
                  description={product.description}
                  image={product.image}
                  onShowDetails={handleShowDetails}
                />
              ))}
          </div>
        </section>
      ))}

      {showDetails && selectedProduct && (
        <div className="product-details">
          <div className="product-details-content">

            <ImageWithLoader src={selectedProduct.image} alt={selectedProduct.name} className="product-image" />
            <h2>{selectedProduct.name}</h2>
            <p>{selectedProduct.price} Р</p>
            <p>{selectedProduct.description}</p>
            <div className="group-button">
              <button onClick={handleCloseDetails}>Закрыть</button>
              <button onClick={handleCloseDetails}>В корзину</button>
            </div>
          </div>
        </div>
      )}

      {/* Модальное окно для выбора категории */}
      {isModalOpen && (
        <div className="category-modal">
          <div className="modal-header">
            <span className="close-button" onClick={closeModal}>×</span>
          </div>
          <div className="modal-content">
            {categories.map((category, index) => (
              <div
                key={category}
                className="modal-category"
                onClick={() => handleCategorySelect(category, index)}
              >
                {category}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
