import React, { useState } from 'react';
import LazyLoad from 'react-lazyload';

const ImageWithLoader = ({ src, alt, className }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div className="image-wrapper">
      {!isLoaded && <div className="image-loader">Загрузка...</div>} {/* Прелоадер */}
      <LazyLoad height={200} once>
        <img
          src={src}
          alt={alt}
          className={`${className} ${isLoaded ? 'loaded' : 'loading'}`} // Добавляем классы
          onLoad={handleImageLoad} // Обработчик загрузки изображения
        />
      </LazyLoad>
    </div>
  );
};

export default ImageWithLoader;
